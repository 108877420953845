import config from "@/helpers/config";
import {
  getArticlesGroupedBySource,
  getImagesGroupedBySource,
  getEntitiesAmounts,
} from "@services/Charts.api";
import { pushChangesToElastic } from "@services/Custom.api";
import PieChart from "@components/backend/PieChart/PieChart";

export default {
  name: "BackendHome",
  components: {
    PieChart,
  },

  data() {
    return {
      apiUrl: config.apiUrl,
      pieChartColors: [
        "#DA8824",
        "#00A7E1",
        "#60269E",
        "#00A887",
        "#D41367",
        "#DAA900",
        "#0047BA",
        "#76777A",
        "#8C827A",
        "#F29100",
        "#29C3EB",
        "#9164CC",
        "#2ED9C3",
        "#F45197",
        "#FFD100",
        "#5887DA",
        "#98989A",
        "#B5ADA5",
        "#FFAD5F",
        "#B4E7FB",
      ],
      pieChartHeight: 360,
      pieChartWidth: 600,
      articlesChartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              "#DA8824",
              "#00A7E1",
              "#60269E",
              "#00A887",
              "#D41367",
              "#DAA900",
              "#0047BA",
              "#76777A",
              "#8C827A",
              "#F29100",
              "#29C3EB",
              "#9164CC",
              "#2ED9C3",
              "#F45197",
              "#FFD100",
              "#5887DA",
              "#98989A",
              "#B5ADA5",
              "#FFAD5F",
              "#B4E7FB",
            ],
            data: [],
          },
        ],
      },
      imagesChartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Data Two",
            backgroundColor: [
              "#DA8824",
              "#00A7E1",
              "#60269E",
              "#00A887",
              "#D41367",
              "#DAA900",
              "#0047BA",
              "#76777A",
              "#8C827A",
              "#F29100",
              "#29C3EB",
              "#9164CC",
              "#2ED9C3",
              "#F45197",
              "#FFD100",
              "#5887DA",
              "#98989A",
              "#B5ADA5",
              "#FFAD5F",
              "#B4E7FB",
            ],
            data: [],
          },
        ],
      },
      isLoading: true,
      entityData: [],
      labelDictionary: {
        _author: "المؤلفون",
        _language: "اللغات",
        _source: "المصادر",
        _category: "الملاحق والإصدارات",
        _type: "نوع المقالات",
        _topic: "التبویب",
        _article: "المقالات",
        _entity: "التسميات",
        _image: "الصور",
        _article_block: "تسميات المقالات",
        _article_image: "صور المقالات",
        _photographer: "المصورون",
        _image_block: "صور التسميات",
        _thesaurus: "المكانز",
        _user: "المستخدمون",
        _user_article: "المقالات للمستخدمين",
        _user_image: "صور للمستخدمين",
        _user_query: "أبحاث المستخدمین للمستخدمين",
      },
    };
  },

  mounted() {
    this.SetData();
  },

  methods: {
    mergeFrontend() {
      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة! قد تستغرق هذه العملية ما يصل إلى ثلاث ساعات`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;

            pushChangesToElastic()
              .then(() => {
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        },
      });
    },
    SetData() {
      this.isLoading = true;

      getArticlesGroupedBySource().then((res) => {
        res.data.payload.forEach((el) => {
          this.articlesChartData.labels.push(el.label);
          this.articlesChartData.datasets[0].data.push(el.amount);
        });

        getImagesGroupedBySource().then((res) => {
          res.data.payload.forEach((el) => {
            this.imagesChartData.labels.push(el.label);
            this.imagesChartData.datasets[0].data.push(el.amount);
          });
        });
      });

      getEntitiesAmounts().then((res) => {
        res.data.payload.forEach(({ amount, label }) => {
          this.entityData.push({ amount, label });
        });
        this.isLoading = false;
      });
    },
    setLabel(label) {
      if (this.labelDictionary[label]) {
        return this.labelDictionary[label];
      } else {
        return label;
      }
    },
  },
};
