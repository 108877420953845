import { Doughnut } from "vue-chartjs";
export default {
  extends: Doughnut,
  props: ["data", "options"],
  mounted() {
    this.addPlugin({
      id: "spacing-between-labels",
      beforeInit: function(chart) {
        chart.legend.afterFit = function() {
          this.height = this.height + 20;
        };
      }
    })
    this.renderChart(this.data, {
      borderWidth: "10px",
      hoverBackgroundColor: "red",
      hoverBorderWidth: "10px",
      legend: {
        display: true,
        position: "top",
        labels: {
          padding: 8,
          font: {
            size: "5"
          }
        }
      },
    });
  },
};
