import { mapActions, mapMutations } from "vuex";

export default {
  name: "SignIn",

  data() {
    return {
      valid: false,
      isLoading: false,
      email: "",
      emailRules: [
        (v) => !!this.validateRequired(v) || "E-mail is required",
        (v) => this.validateEmailFormat(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!this.validateRequired(v) || "password is required",
      ],
    };
  },

  methods: {
    ...mapActions("auth", ["loginBackend"]),
    ...mapMutations("notification", ["notify"]),

    async signIn() {
      var user = {
        email: this.email,
        password: this.password,
      };

      if (this.validateUser(user)) {
        this.isLoading = true;
        await this.loginBackend(user).then(
          () => {
            if (this.$route.query.redirect) {
              this.$router.push({path: this.$route.query.redirect});
            } else {
              this.$router.push({name: "BackendHome"});
            }
          },
          () => {
            this.notify({
              message: "لم يتم تسجيل دخولك. يرجى المحاولة مرة أخرى",
              show: true,
            });
          }
        );
        this.isLoading = false;
      } else {
        this.notify({
          message: "هناك خطأ ما. الرجاء معاودة المحاولة في وقت لاحق.",
          show: true,
        });
      }
    },

    validateRequired(value) {
      return value.length > 0;
    },

    validateEmailFormat(email) {
      return /.+@.+/.test(email);
    },

    validateUser(user) {
      return (
        this.validateRequired(user.email) &&
        this.validateRequired(user.password) &&
        this.validateEmailFormat(user.email)
      );
    },
  },
};
